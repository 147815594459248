.event-image-new {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.card-details-on-card {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  position: relative;
  top: -10px;
  background-color: var(--side-bar-bg-color);
  padding-bottom: 10px;
}
.image-div-on-card {
  background-color: var(--side-bar-bg-color);
  position: relative;
  border-radius: 10px;
}
.alert-event-type-label {
  display: flex;
  padding-bottom: 0px;
  color: "var(--card-text-color)";
}
.alert-event-type-label h4 {
  font-family: "Roboto";
  color: "var(--card-text-color)";
}
.alert-card-time-date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-left: auto;
  text-align: right;
  color: "var(--black-white-darkmode)";
}
.card-details-on-card {
  border-radius: 10px;
}
.main-alert-event-card {
  position: relative;
}

.alert-even-card-div-loc-chan {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 30px;
  margin-bottom: 5px;
}
.alert-even-card-div-loc-chan-message {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 43px;
  overflow: hidden;
}

.input-remark-alert-event {
  height: 35px;
  background-color: var(--input-select-bg-color);
  border-radius: 5px;
  outline: 0px;
  border: none;
  color: var(--card-text-color);
}
.input-remark-alert-event-div {
  height: 35px;
  background-color: var(--input-select-bg-color);
  border-radius: 5px;
  outline: 0px;
  border: none;
  color: var(--card-text-color);
}

.alert-card-action-btn {
  width: 50px;
  height: 22px;
  background: linear-gradient(
    180deg,
    #1e66e7 0%,
    rgba(72, 136, 212, 0.91) 98.96%
  );
  border: none;
  outline: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: 5px;
  border-radius: 10px;
}
.alert-revert-btn-modal {
  width: 50px;
  height: 29px;
  background-color: #fa9907;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.alert-revert-btn {
  width: 50px;
  height: 29px;
  background-color: #fa9907;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  margin-left: 10px;
}
.priority-div {
  padding-right: 10px;
  top: 186px;
  left: -1px;
  border-radius: 0px 15px 15px 0px;
  padding-left: 5px;
  color: white;
  background: #ba050c;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.priority-div-event {
  padding-right: 10px;
  top: 186px;
  left: -1px;
  border-radius: 0px 15px 15px 0px;
  padding-left: 5px;
  color: white;

  background: #05ba14;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.card-shadow-alert-event-card-light {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.card-shadow-alert-event-card-light:hover {
  transform: scale(1.02);
}
.card-shadow-alert-event-card-dark {
  /* box-shadow: rgba(161, 160, 160, 0.75) 0px 0.0625em 0.0625em,
    rgba(131, 128, 128, 0.75) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 2px inset; */
}
.card-shadow-alert-event-card-dark:hover {
  transform: scale(1.02);
}

/* ============================ Modal css ============================*/
.card-title-modal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: var(--black-white-darkmode);
  letter-spacing: -0.02em;
}
.modal-card-text-value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: var(--black-white-darkmode);
  margin-left: 5px;
}
.alert-field-icon {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--black-white-darkmode);
  letter-spacing: -0.02em;
  margin-right: 5px;
  font-size: 14px;
}
.modal-text-area {
  width: 100% !important;
  height: 150px;
  width: 300px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  resize: none;
  outline: none;
}

.modal-text-area:disabled {
  background-color: transparent;
}

.alert-card-action-btn-modal {
  width: 120px;
  height: 35px;
  background: linear-gradient(
    180deg,
    #1e66e7 0%,
    rgba(72, 136, 212, 0.91) 98.96%
  );
  border: none;
  outline: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: 5px;
  border-radius: 10px;
}
.alert-main-field-modal {
  display: flex;
}
.alert-card-action-btn-modal:hover {
  transform: scale(1.05);
}
.alert-compare-image {
  width: 600px;
}
@media (max-width: 1150px) {
  .alert-compare-image {
    width: 600px;
  }
}
@media (max-width: 992px) {
  .alert-compare-image {
    width: 400px;
  }
}
@media (max-width: 580px) {
  .alert-compare-image {
    width: 450px;
  }
}
@media (max-width: 760px) and (min-width: 580px) {
  .alert-compare-image {
    width: 500px;
  }
}
@media (max-width: 450px) {
  .alert-compare-image {
    width: 300px;
  }
}
