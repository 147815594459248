@font-face {
  font-family: "Roboto";
  src: url("./../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
:root {
  --login-btn-color: #4318ff;
  --side-bar-bg-color: #ffffff;
  --side-item-color: #2b3674;
  --top-bar-bg-color: #e0e5f2;
  --card-text-color: #2b3674;
  --input-select-bg-color: #f4f7fe;
  --card-icon-div-bg: #f4f7fe;
  --chatbot-bg-color: #ffffff;
  --chatbot-welcome-text-color: black;
  --server-sent-message-bg: #e1e1e1;
  --user-sent-message-bg: #6e4dff;
  --sever-sent-msg-text: #ffff;
  --loading-skeleton: #e6e3dc;
  --heatmap-card-bgcolor: #f4f7fe;
  --black-white-darkmode: "white";
  --primary-background-color: "#425CDF";
}

.divbelowtopbarsecond {
  /* // backgroundColor: colorConfigs.sidebar.bg,
  borderRadius: "20px",
  marginTop: "20px", */
  background-color: var(--side-bar-bg-color);
  border-radius: 20px;
  margin-top: 20px;
}

table.device-status-table td {
  border: 2px solid #c5ccef !important;
  transform: rotate(0.04deg);
  text-align: center;
  height: 70px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
}
table.device-status-table {
  border-collapse: collapse;
  width: 98%;
  background: var(--side-bar-bg-color);
  border: 2px solid rgba(105, 125, 255, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 10px;
}
table.device-status-table th {
  border: none;
  height: 70px;
  text-align: center;
  background-color: #697dff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  /* or 94% */
  letter-spacing: -0.02em;
  color: #ffffff;
  min-width: 100px;
}

/* css for making device status table responsive */
table.device-status-table td {
  font-size: 20px;
}

table.device-status-table td span {
  font-size: 20px;
}
@media (max-width: 600px) {
  table.device-status-table th {
    font-size: 22px;
  }
  table.device-status-table td {
    font-size: 18px;
  }
  table.device-status-table td span {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  table.device-status-table th {
    font-size: 14px;
  }
  table.device-status-table td {
    font-size: 12px;
  }
  table.device-status-table td span {
    font-size: 12px;
  }
}
.shakeAnimation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

table.class-table-rhms td {
  transform: rotate(0.04deg);
  text-align: center;
  height: 70px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
table.class-table-rhms {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-collapse: collapse;
  background: var(--side-bar-bg-color);
}
table.class-table-rhms th {
  border: none;
  height: 70px;
  text-align: center;
  background-color: #697dff;
  font-family: "Roboto";
  font-style: normal;
  line-height: 30px;
  /* or 94% */
  letter-spacing: -0.02em;
  color: #ffffff;
  font-weight: 900;
  font-size: 16px;
}
table.class-table-rhms tr {
  border: 3px solid rgba(105, 125, 255, 0.5);
}

/* css code for making remote monitoring table responsive */
.remote-table-index {
  min-width: 80px;
}
.remote-table-hdd-recording {
  min-width: 150px;
}
.remote-table-last-online {
  min-width: 150px;
}
.remote-table-details {
  width: 80px;
  font-size: 10px;
}
.remote-table-nvr-hdd-health {
  width: 100px;
}
.remote-table-nvr-dvr {
  min-width: 150px;
}
.remote-table-nvr-online {
  width: 80px;
}
.remote-table-camera-offline {
  width: 80px;
  font-size: 12px;
}
.remote-table-last-available {
  min-width: 120px;
}

@media (max-width: 600px) {
  .remote-table-hdd-recording {
    min-width: 150px;
    font-size: 14px;
  }
  .remote-table-details {
    width: 100px;
    font-size: 10px;
  }
  .remote-table-nvr-hdd-health {
    width: 80px;
    font-size: 14px;
  }
  .remote-table-nvr-dvr {
    min-width: 200px;
  }
  .remote-table-nvr-online {
    width: 100px;
    font-size: 14px;
  }
  .remote-table-camera-offline {
    width: 80px;
    font-size: 10px;
  }
  .remote-table-last-online {
    width: 400px;
    font-size: 14px;
  }
}
.remote-monitoring-detail-modal {
  max-height: 70vh;
  overflow-y: auto;
}
.remote-monitoring-modal-parent {
  margin-top: 5vh;
}
.offline-span-device-status {
  background: rgba(227, 26, 26, 0.16);
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* or 125% */
  padding: 5px 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fc0808;
}
.online-span-device-status {
  background: rgba(1, 181, 116, 0.23);
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* or 125% */
  padding: 5px 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #33c200;
}

table.user-profile-setting-table td {
  transform: rotate(0.04deg);
  text-align: center;
  height: 55px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  border: 2px solid #c5ccef !important;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
table.user-profile-setting-table {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-collapse: collapse;
  background: var(--side-bar-bg-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid #c5ccef !important;
  overflow-x: auto;
}
table.user-profile-setting-table th {
  border: 2px solid #c5ccef !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 42px;
  /* or 210% */
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
  min-width: 150px;
}
table.user-profile-setting-table td {
  min-width: 150px;
}
table.rhms-modal-table {
  display: flex;
  flex-direction: column;
  background: var(--side-bar-bg-color);
  width: 100%;
}
table.rhms-modal-table tr {
  display: flex;
}
table.rhms-modal-table tr td:first-child {
  flex: 1;
  border: 1px solid rgba(105, 125, 255, 0.5);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-left: 10px;
  line-height: 40px;
  /* or 135% */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
}

.rhms-table-text-modal-details {
  flex: 1;
  border: 1px solid rgba(105, 125, 255, 0.5);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 42px;
  padding-left: 10px;
  line-height: 40px;
  /* or 135% */
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
}

.retail-top-card {
  margin-top: 20px;
  width: 100%;
  height: 130px;
  background: var(--side-bar-bg-color);
  border-radius: 20px;
  display: flex;
  line-height: 100%;
  align-items: center;
}

.dashcaricondiv {
  height: 70px;
  width: 70px;
  background-color: var(--card-icon-div-bg);
  /* background-color: #313235; */
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  border-radius: 40px;
  padding-top: 10px;
}

h4 {
  font-family: "Roboto";
  font-style: normal;
  margin: 0px;
  /* identical to box height, or 124% */
  padding: 0px;
  /* letter-spacing: -0.02em; */
  color: var(--card-text-color) !important;
  font-weight: 900;
}

h3 {
  font-family: "Roboto";
  font-style: normal;
  margin: 0px;
  /* identical to box height, or 124% */
  padding: 0px;
  /* letter-spacing: -0.02em; */
  color: var(--card-text-color) !important;
  font-weight: 900;
}
h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */
  letter-spacing: -0.02em;
  color: var(--card-text-color) !important;
}
h6 {
  font-family: "Roboto";
  font-style: normal;
  margin: 0px;
  /* identical to box height, or 124% */
  padding: 0px;
  /* letter-spacing: -0.02em; */
  color: var(--card-text-color) !important;
  font-weight: 900;
  padding: "0px";
  margin: "0px";
}

.chart-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
  color: var(--card-text-color);
}

.card-heading-retail {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--card-text-color);
  opacity: 0.7;
}
.card-paragraph-retail {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  /* Text */
  color: var(--card-text-color);
  opacity: 0.7;
  padding-right: 4px;
}

.select-input-div {
  background-color: var(--side-bar-bg-color);
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.11);
}
.select-input-lebel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
  margin-left: 0.2rem;
  margin-bottom: 0.5rem;
}

.select-input-field {
  background-color: var(--input-select-bg-color);
  border: none !important;
  outline: 0px !important;
  height: 40px;
  width: 100%;
  color: var(--card-text-color);
  border-radius: 8px;
  margin-top: 0;
  padding-top: 0;
  padding: 0px;
  text-indent: 0px;
  display: inline-block;
  margin: 0px;

  /* padding-left: 10px; */
}
.select-input-field-date {
  background-color: var(--input-select-bg-color);
  border: none !important;
  outline: 0px !important;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  margin-top: 0;
  padding-top: 0;
  padding: 0px;
  text-indent: 0px;
  display: inline-block;
  margin: 0px;
  padding-left: 15px;
}
.custom-input-date-text {
  color: var(--card-text-color);
}

.sidebar-color {
  width: 280px;
  flex-shrink: 0;
}
.signButton {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  /* gap: 10px; */
  margin-top: 20px;
  width: 100%;
  height: 67px;
  background: var(--login-btn-color); /* use the CSS variable here */
  border-radius: 16px;
  border: none;
  outline: none;
}
.Login-form-lebel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */
  letter-spacing: -0.02em;
  color: #2b3674;
}
#sidebar-logo-image {
  height: 60px;
  width: 340px;
  margin-top: 20px;
  padding: 2px;
}
.maindiv {
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
#headSign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
#allrightreserved {
  position: absolute;
  height: 24px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #a3aed0;
}
.media-img {
  width: 228px;
  height: 67px;
}
.pwd {
  position: relative;
}

.fa-eye {
  color: #000;
}
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.allReservedLoginpage {
  transform: translate(30%, 30%);
}
.fill img {
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
}
.lebelLogin {
  width: 56.04px;
  height: 18.68px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}
.form-parent {
  /* width: 547px; */
}
.inputLogin {
  box-sizing: border-box;
  width: 100%;
  height: 66.71px;
  text-indent: 10px;
  /* Secondary/Grey/500 */
  border: 1px solid #e0e5f2;
  border-radius: 16px;
  line-height: 100%;
}
.remark-input {
  box-sizing: border-box;
  width: 80%;
  height: 66.71px;
  text-indent: 10px;
  /* Secondary/Grey/500 */
  border: 1px solid #e0e5f2;
  border-radius: 16px;
  line-height: 100%;
}

.logoimagediv {
  height: 800px;
  width: 100%;
}
.logoImage {
  margin-bottom: 60px;
}
.logoImage img {
  max-width: 460px;
  height: 100%;
  margin-left: 70px;
  margin-top: 70px;
  width: 100%;
}
.form {
  margin-left: 70px;
  text-align: left;
}
#appStoreImage {
  margin-left: 00px;
}
#forgetpass {
  text-align: right;
}

.bg-image {
  background: url(../images/png_for_login_page.png) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999;
  background-size: cover;
  z-index: 999;
  max-width: 100%;
  height: 114%;
}
.cardsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.retailcard {
  flex: 1 1 20rem;
  border-radius: 20px;
  margin: 10px 20px;
  background-color: #ffffff;

  padding: 10px 20px;
  align-self: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.cardItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardItemAlign {
  margin-right: 40px;
  margin-top: 10px;
}
.iconDiv {
  height: 81px;
  width: 78px;
  background-color: #dad7fe;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 40px;
  padding-top: 10px;
}
.alertcard {
  flex: 1;
  margin: 1px 12px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px 20px;
  align-self: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.alertcardItem {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.alertcardItemAlign {
  margin-right: 20px;
  margin-top: 10px;
}
.alerticonDiv {
  height: 56px;
  width: 56px;
  background-color: #f4f7fe;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 0px;
  border-radius: 40px;
}
.newTickealerticonDiv {
  height: 56px;
  width: 56px;
  background-color: #4481eb;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 0px;
  border-radius: 40px;
  padding-top: 10px;
  margin-left: 40px;
}

.alertableCard {
  flex: 1 1 20rem;
  margin: 10px 20px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px 20px;
  align-self: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.profileInput {
  width: 100%;
}
.profileParaColor {
  margin-top: 20px;
  height: 20px;
  margin: 0px;
}
.profileForm {
  margin: 20px;
  padding: 20px;
  width: 50%;
}
.profileSaveButton {
  border-radius: 10px;
  width: 80px;
  border: none;
  height: 40px;
}

.profileButtonText {
  width: 100%;
  height: 46.62px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 42px;
  /* or 88% */
  letter-spacing: -0.02em;
  color: #f8f9fd;
  margin-top: 15px;
}
.profileIdInput {
  border: none;
  border-radius: 20px;
  outline: 0px;
  height: 30px;
  display: block;
}
.image {
  width: 100%;
  position: relative;
}
.ab {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  padding-right: 1px;
}

.lebelInputSelect {
  color: #2b3674;
}

.statusDivTableOff {
  background-color: rgba(227, 26, 26, 0.16);
  border-radius: 25px;
  width: 110px;
  height: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.statusDivTableOn {
  background-color: rgba(1, 181, 116, 0.23);
  border-radius: 25px;
  width: 110px;
  height: 35px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.statusParaTextOff {
  display: table;
  margin: 0px auto 0px auto;
  color: #fb1010;
}

.statusParaTextOn {
  /* color: #33C200; */
  display: table;
  margin: 0px auto 0px auto;
  color: #33c200;
}
#statusSwitch {
  color: #18e0a7;
}
.showDetailsNvrButton {
  background-color: #d8d8d8;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: 0px;
}
.cam-offline-btn-rhms {
  background-color: #eff1fe;
  border-radius: 10px;
  margin-left: 3px;
  padding-left: 2px;
  border: none;
  padding: 10px;
  color: 000000;
}
.showDetailsNvrButton span {
  padding: 4px;
}

.paraColorOfNvrRed {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fb1010;
}
.buttonInsideShowButton {
  padding-right: 10px;
  border-radius: 20px;
  background-color: #eff1fe;
  padding-left: 10px;
  width: 27px;
  height: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
}

.table-td-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */
  justify-content: center;
  text-align: center;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;
}
.paginationDiv {
  text-align: center;
  margin-top: 20px;
}
.alertchartdiv {
  background: var(--side-bar-bg-color);
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-top: 25px;
  height: 400px;
  padding: 0;
}

.thisyearbuttonalert {
  width: 123px;
  height: 37px;
  border-radius: 7px;
  background-color: #f4f7fe;
  border: none;
  outline: 0px;
  float: left;
}
.divabovesideIcon {
  text-align: center;
}
.alert-div-log-news-agrex-btn {
  text-align: center;
  padding-top: 30px;
}
.resolveCountColor {
  color: #e91407;
  margin: 0;
  padding: 0;
}
.unResolveCount {
  color: #05cd99;
  margin-top: 0px;
  margin: 0;
  padding: 0;
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding: 10px;
}
.box .percent {
  position: relative;
  width: 150px;
  height: 150px;
}
.box .percent svg {
  position: relative;
  width: 200px;
  height: 200px;
}
.box .percent svg circle {
  width: 150px;
  height: 150px;
  fill: #dad7fe;
  stroke: #000;
  stroke-width: 10;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  margin: 0px;
}
.box .percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.box .percent .number h2 {
  font-size: 20px;
  color: black;
}
.box .percent .number p {
  font-size: 15px;
  color: black;
  float: none;
  padding-top: 60px;
  float: left;
}
.box .text {
  padding: 10px 0 0;
  color: #0999;
}
.box .percent svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #dad7fe;
}
.box .percent svg circle:nth-child(2) {
  stroke-dashoffset: calc(440 - (440 * 20) / 100);
  stroke: #4339f2;
}

.iconButtonDash {
  border: none;
  outline: 0px;
  background-color: white;
  padding-top: 65px;
}
.forgetPassdiv {
  display: grid;
  place-items: center center;
  /*place-items is a shorthand for align-items and justify-items*/
  /* margin-top: 100px; */
}
.forgetPassForm {
  place-items: center center;
  width: 500px;
}
.forgetPassSubmitButtom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  gap: 10px;
  width: 50%;
  height: 67px;
  background: #4318ff;
  border-radius: 16px;
  border: none;
  outline: none;
  margin-left: 130px;
}

.spaceremoveofpara {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  letter-spacing: -0.02em;

  color: #a3aed0;
}
.spaceremoveofHeading {
  margin: 0px;
  padding: 0px;
}
.alertDetailTablediv {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
}
.alert-DetailTable-divIcon-profile {
  width: 44px;
  height: 40px;
  background-color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.alert-DetailTable-div-Email {
  width: 44px;
  height: 40px;
  background-color: #4339f2;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
}
.alert-DetailTable-div-AlertType {
  width: 44px;
  height: 40px;
  background-color: #dad7fe;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.alert-DetailTable-PersonIcon {
  color: #000000;
  font-size: 34px;
  font-weight: 3;
}
.alert-storewise-card {
  background-color: #ffffff;
  border-radius: 20px;
  padding-left: 10px;
  padding-top: 10px;
  /* padding-bottom: 0px; */
  margin-top: 10px;
  align-self: normal;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* flex-wrap: wrap; */
}
.alert-storwise-icon-div {
  height: 40px;
  width: 40px;
  background-color: #4339f2;
  padding-left: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}
.alert-charts-icon-div {
  background-color: #f4f7fe;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  float: right;
}

p {
  font-family: "Roboto";
  font-style: normal;
  color: #030229;
  opacity: 0.7;
  padding: 0px;
  margin: 0px;
  font-weight: 900;
}

.my-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
}
.pagination-div {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.form-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 175% */
  letter-spacing: -0.02em;
  color: var(--card-text-color);
  margin: 0;
  padding: 0;
}
.custom-tabs .nav-link.active {
  background-color: transparent !important;
  border: none !important;
}

.custom-tabs .nav-link {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0.5rem 1rem !important;
  position: relative;
}

.form-label-login-page {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */

  letter-spacing: -0.02em;

  color: #2b3674;
}
.profile-page-button-label {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */

  letter-spacing: -0.02em;

  color: var(--card-text-color);
}
.user-card-email-mob-loc {
  width: 210px;
  height: 28.54px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */
  letter-spacing: -0.02em;
  color: #eff1fe;
  display: flex;
  margin-top: 15px;
}
.user-card-email-mob-loc span {
  margin-right: 10px;
}
.profile-detail-form {
  width: 100%;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  margin-top: 10px;
}

.profile-dropdown-div {
  background: var(--side-bar-bg-color);
  border-radius: 4px;
}
.profile-dropdown-form-new {
  border-radius: 2px;
}
.add-email-mob-btn {
  width: 60px;
  height: 30px;
  background-color: #2b3674;
  border: none;
  outline: 0;
  border-radius: 7px;
  margin-left: 20px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-submit-btn {
  background-color: #2b3674;
  border: none;
  outline: 0;
  width: 94px;
  height: 42px;
  border-radius: 5px;
}
.profile-submit-btn span {
  width: 62px;
  height: 42px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 42px;
  /* identical to box height, or 175% */
  letter-spacing: -0.02em;
  color: #fafbfe;
}
.form-check-label span {
  height: 42px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 175% */
  padding-left: 5px;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
}
.form-check {
  display: flex;
}
.profile-view-btn {
  background-color: #2b3674;
  border: none;
  outline: 0;
  width: 94px;
  height: 42px;
  border-radius: 10px;
  margin-left: 20px;
}
.profile-view-btn span {
  width: 62px;
  height: 42px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 42px;
  /* identical to box height, or 175% */
  letter-spacing: -0.02em;
  color: #fafbfe;
}
.form-control {
  margin: 0;
  padding: 0;
}
.login-store-logo img {
  height: 60px;
}
@media (max-width: 400px) {
  .login-store-logo img {
    height: 60px;
  }
}
.profile-email-id {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 40px;
  /* or 150% */
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(89, 103, 128, 0.68);
}
.profile-location-store {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  margin-top: 10px;
  /* or 210% */
  letter-spacing: -0.02em;
  color: rgba(89, 103, 128, 0.5);
}
.profile-role-descri-per {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  margin-top: 10px;
  /* or 175% */
  letter-spacing: -0.02em;
  color: #2b3674;
}
.profile-add-user-btn {
  background-color: #2b3674;
  border: none;
  outline: 0;
  width: 112px;
  height: 36.32px;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-add-user-btn span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 42px;
  /* or 262% */
  letter-spacing: -0.02em;
  color: #ffffff;
}
.profile-user-email {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  /* or 150% */
  letter-spacing: -0.02em;
  color: rgba(89, 103, 128, 0.68);
  line-height: 90%;
  margin-left: 10px;
}
.profile-user-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  /* or 140% */
  margin-left: 10px;
  letter-spacing: -0.02em;
  color: #3a7bd5;
  line-height: 100%;
}

input {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.add-user-modal-icon {
  width: 47px;
  height: 47px;
  left: 20px;
  top: 26px;
  border: 1.5px solid rgba(120, 139, 173, 0.3);
  margin: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-add-user-modal-form {
  width: 338px;
  margin: 40px;
}
.profile-add-user-modal-form input {
  width: 338px;
  height: 38px;
  left: 36px;
  top: 113px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.form-select {
  width: 20px;
}

#table-device-status > tbody > tr > td {
  vertical-align: middle;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  /* or 125% */
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}
#table-device-status > thead > tr > th {
  padding: 15px;
}
#table-device-status > thead > tr > th > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 30px;
  /* or 94% */
  letter-spacing: -0.02em;
  color: #000000;
}
#table-device-control > tbody > tr > td {
  vertical-align: middle;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  /* or 125% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}
#table-device-control > thead > tr > th {
  padding: 15px;
}
#table-device-status > thead > tr > th {
  padding: 15px;
}

#table-device-control > thead > tr > th > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 30px;
  /* or 94% */
  letter-spacing: -0.02em;
  color: #000000;
}
.deviceControlTbale {
  background-color: #ffffff;
  padding: 10px;
  border-collapse: collapse;
  border-spacing: 0px;
  border-radius: 6px;
  border-left: 0px;
}

.alert-modal-paratext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  /* or 86% */
  text-align: center;
  letter-spacing: -0.02em;
  color: #969696;
}
.plusBtn {
  padding: 0;
}
::placeholder {
  padding-left: 4px;
}
.footer-item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  letter-spacing: -0.02em;
  /* Secondary/Grey/600 */
  color: #a3aed0;
  margin: 10px;
}
.alert-card-location-chanel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  flex: 1;
  min-width: 0;
  line-height: 18px;
  /* identical to box height, or 143% */
  letter-spacing: -0.02em;
  color: var(--card-text-color);
}
/* .alert-card-time-date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4318ff;
} */
.alert-card-resolve-dismiss {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  letter-spacing: -0.02em;
  border-radius: 15px;
  color: #ffffff;
  max-width: 120px;
}
.alert-card-reso-btn {
  width: 100%;
  height: 25px;
  background-color: #41cc00;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
}
.alert-card-revert-btn {
  width: 100%;
  height: 25px;
  background-color: #fa9907;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
}
.alert-card-diss-btn {
  width: 100%;
  height: 25px;
  background-color: #e91407;
  border: none;
  outline: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.alert-logo-image-div-news {
  width: 255px;
  height: 58px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.alert-new-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  letter-spacing: -0.02em;
  color: #2b3674;
  padding-top: 20px;
}
.alert-news-on-agrex {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: -0.02em;
  color: #a3aed0;
  padding-top: 40px;
}
.alert-update-btn {
  background: #4318ff;
  border-radius: 16px;
  width: 90%;
  height: 46px;
  border-radius: 16px;
  border: none;
  outline: 0px;
}
.divsettingDashpage {
  background: #ffffff;
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-top: 40px;
}
.head-text-app {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 25px;
  /* Colors / Black / 100% */
  color: var(--card-text-color);
}
.dash-chart-text-div {
  padding: 18px;
}
.dash-alert-details-div {
  padding: 18px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding-top: 40px;
}
.dash-alert-store-wise-div {
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-right: 0px;
}
.dash-text-div {
  margin: 15px;
}
.dash-chart-bottom-space {
  padding-bottom: 20px;
  padding-right: 20px;
}
.dash-male-female-div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.dash-male-btn {
  width: 20px;
  height: 20px;
  border: none;
  outline: 0px;
  background-color: #4339f2;
  border-radius: 20px;
}
.dash-female-btn {
  width: 20px;
  height: 20px;
  border: none;
  outline: 0px;
  background-color: #dad7fe;
  border-radius: 20px;
}
.dash-alerts-store-wise {
  border: 2px solid rgba(89, 103, 128, 0.17);
  margin-top: 40px;
  border-radius: 15px;
}
.heat-map-view-btn {
  background-color: #f4f7fe;
  width: 115px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: 0px;
}
.custom-select {
  font-family: Arial;
}

.head-sub-text-app {
  top: 3501.24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-left: 15px;
  /* Theme Color/Black */

  color: #313131;
}
.custom-tooltip {
  border-radius: 40px;
  background-color: #ffffff;
  text-align: center;
  justify-content: center;
  width: 85.7px;
  height: 85.7px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
}
.resolveCountColor {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #e91407;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(205, 5, 5, 0.1);
  border-radius: 58px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.unResolveCount {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  text-align: center;
  letter-spacing: -0.02em;
  color: #05cd99;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(5, 205, 153, 0.1);
  border-radius: 58px;
}

.login {
  min-height: 100vh;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}
.year1-filter-btn-reatail-dash {
  width: 35px;
  height: 15px;

  background: #dad7fe;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  outline: 0px;
}
.year2-filter-btn-reatail-dash {
  width: 35px;
  height: 15px;
  background: #4339f2;
  border-radius: 20px;
  /* Inside auto layout */
  flex: none;
  padding-right: 10px;
  order: 0;
  flex-grow: 0;
  border: none;
  outline: 0px;
  /* Inside auto layout */
}
.profile-toggle-div-btn {
  width: 100%;
  background-color: #ffffff;
  outline: 0px;
  border: none;
  border-radius: 20px;
  height: 60px;
}

.search-input-field {
  background-color: var(--input-select-bg-color);
  border: none;
  outline: 0px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  padding: 0px;
  text-indent: 10px;
  margin: 0px;
  color: var(--card-text-color);
}
.forgetPassword-warning {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* Theme Color/Gry 3 */
  color: #bdbdbd;
  /* Inside auto layout */
  padding: 0;
  margin: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.Password-not-matched-warning {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* Theme Color/Gry 3 */
  color: red;
  /* Inside auto layout */
  padding: 0;
  margin: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.form-group {
  margin: 0px;
  padding: 0px;
  line-height: 100%;
  margin-top: 20px;
}
.user-update-form-setting {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 100%;
  /* or 210% */
  letter-spacing: -0.02em;
  color: var(--card-text-color);
}

.card-body-div {
  margin-left: 20px;
  padding-top: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}
.header-background {
  background-color: #697dff;
}
.modal-title-color {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
  /* or 117% */
  letter-spacing: -0.02em;
  color: #ffffff !important;
}
.modal-key-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  /* or 135% */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2b3674;
}
.modal-value-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%px;
  /* or 135% */
  letter-spacing: -0.01em;
  color: #141415;
}

.nvr_online_color {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #33c200;
}
.modal-value-text-offline-cam {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%px;
  /* or 135% */
  letter-spacing: -0.01em;
  color: #f32840;
}

.chart-container {
  background: var(--side-bar-bg-color);
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  line-height: 100%;
  margin-top: 25px;
}
.chart-container-alert {
  background: var(--side-bar-bg-color);
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  line-height: 100%;
  margin-top: 20px;
  height: 400;
}
.chart-div {
  /* margin: 20px; */
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.chart-div-alert {
  /* margin: 20px; */
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.alert-chart-icon-btn {
  width: 33px;
  height: 33px;
  right: 32px;
  top: 28px;
  background: #f4f7fe;
  border-radius: 10px;
}
.alert-chart-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 110%;
  /* or 200% */
  letter-spacing: -0.02em;
  color: #a3aed0;
  padding: 0px;
  margin: 0px;
}
.dashline-chart-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  /* or 200% */
  letter-spacing: -0.02em;
  /* color: var(--side-item-color); */
  padding: 0px;
  margin: 0px;
}

.nav-tabs .nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.02em;
  color: #a3aed0;
}

.nav-tabs .nav-link.active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.02em;
  /* Secondary/Grey/600 */
  color: #2b3674;
  border: none !important;
  background-color: transparent !important;
}

.alert-dissmiss-btn {
  width: 70px;
  height: 25px;
  background-color: #4339f2;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-left: 10px;
}
.alert-revert-btn {
  width: 70px;
  height: 25px;
  background-color: #fa9907;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-left: 10px;
}

.parent-container {
  display: flex;
  justify-content: center;
}

/* #myChart .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0 0 0 5px round 20px 20px);
} */

.alert-even-card-div-loc-chan {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 30px;
  margin-bottom: 5px;
}
.alert-even-card-div-loc-chan-message {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 40px;
}

.alert-event-card-input {
  border: none;
  border-radius: 20px;
  outline: 0px;
  height: 30px;
  display: block;
  height: 30px;
  width: 100%;
  background-color: var(--card-text-color);
  border-radius: 30px;
}

.p-viewer {
  z-index: 0;
  position: absolute;
  top: 22px;
  right: 10px;
}

.btn-inside-inputfield {
  border: none;
  outline: 0px;
}

i.bi.bi-plus {
  font-size: 1.2rem; /* or any other size you want */
}

.pp-viewer {
  z-index: 0;
  float: right;
  margin-right: 5px;
  margin-top: 0;
  /* position: absolute;
  top: 0%;
  right: 10px; */
}

.btn-inside-inputfield {
  border: none;
  outline: 0px;
}

i.bi.bi-plus {
  font-size: 1.4rem; /* or any other size you want */
}

.not-registred-yet {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  /* or 186% */
  letter-spacing: -0.02em;
  color: #2b3674;
}

.rdrMonth {
  /* padding: 0 0.833em 1.666em 0.833em;
   */
  height: 245px;
}
.rdrDays {
  padding-bottom: 10px;
}

.heat-map-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: var(--card-text-color);
  white-space: nowrap;
  margin-left: 24px;
}

.heatmap-show-Button {
  width: 140px;
  height: 53px;
  border: none;
  outline: 0px;
  border-radius: 20px;
  margin-top: 10px;
}
.heatmap-button-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 42px;
  /* or 168% */
  letter-spacing: -0.02em;
  color: #2b3674;
}
/* css for making heatmap button responsive */
@media (max-width: 400px) {
  .heatmap-show-Button {
    width: 100px;
    height: 43px;
    border: none;
    outline: 0px;
    border-radius: 20px;
    margin-top: 10px;
  }
  .heatmap-button-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 42px;
    /* or 168% */
    letter-spacing: -0.02em;
    color: #2b3674;
  }
}
@media (max-width: 320px) {
  .heatmap-show-Button {
    width: 70px;
    height: 35px;
    border: none;
    outline: 0px;
    border-radius: 20px;
    margin-top: 8px;
  }
  .heatmap-button-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 42px;
    /* or 168% */
    letter-spacing: -0.02em;
    color: #2b3674;
  }
  .heat-map-time {
    margin-left: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: var(--card-text-color);
    white-space: nowrap;
    font-size: 12px;
  }
}
.alert-modal-text-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 29px;
  line-height: 30px;
  /* or 103% */
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}
.alert-card-reso-btn-modal {
  width: 100%;
  height: 25px;
  background-color: #41cc00;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-right: 10px;
}

.alert-revert-btn-modal {
  width: 70px;
  height: 25px;
  background-color: #fa9907;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.rdrMonthsVertical {
  background-color: #ffffff;
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 99;
}
.rdrMonthAndYearWrapper {
  background-color: #ffffff;
  box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 99;
}

.rdrInputRanges {
  box-shadow: -1px 8px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  height: 110px;
}

.date-range-picker-container {
  background-color: aqua;
}
.rdrStaticRanges {
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 99;
}

.date-range-picker-container {
  position: absolute;
  z-index: 99;
  background-color: #ffffff;
  box-shadow: "0px 0px 5px rgba(0, 0, 0, 0.3)";
}

/* position: "absolute",
zIndex: 99,
display: showPicker ? "block" : "none",
backgroundColor: "red",
boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)", */

.form-section {
  background-color: #fff;
  padding: 20px;
}
.image-section {
  /* background-image: url("your-image.jpg"); */
  background: url(../images/png_for_login_page.png) no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any content that overflows the element */
}

.forgot-password-bg-image {
  padding: 0px;
  margin: 0px;
}
@media (max-width: 767px) {
  .image-section {
    min-height: 50vh; /* Set a smaller height for mobile devices */
    height: auto; /* Allow the height to adjust to the content */
    background-size: contain; /* Adjust the background size for mobile devices */
  }
  /* 
  body {
    overflow: hidden;
  } */

  /* Hide the scrollbar in WebKit browsers */
  ::-webkit-scrollbar {
    display: none;
  }
}
.login-form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 100px;
  /* padding-left: 20px; */
  /* float: right; */
}

/* .apexcharts-legend {
  pointer-events: none;
} */

.submit-button {
  width: 100%;
  height: 50px;
}

.image-section > .d-flex {
  height: 100%;
  color: #fff;
}

@media (max-width: 767px) {
  .image-section > .d-flex > div {
    text-align: center;
  }
}

.ticket-generate-button {
  height: 25px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b2559;
  outline: 0px;
  border: none;
  border-radius: 20px;
  padding: 14px 14px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.multilebel-dropdown-item {
  padding-left: 4px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  opacity: 0.7;
}

.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app-loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-top-card-logo-div-background {
  height: 320px;
  background-repeat: no-repeat;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
}

.list-item {
  width: 33.33%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.tooltip {
  background-color: #ffffff;
}

.heat-map-data {
  display: inline-block; /* or inline-flex */
}

.alert-pie-chart-text .apexcharts-legend.apx-legend-position-right,
.alert-pie-chart-text .apexcharts-legend.apx-legend-position-left {
  flex-direction: column;
  bottom: 0;
  text-align: initial;
}

.chart-checkbox {
  width: 15px;
  height: 15px;
  border: none;
  outline: 0px;
}
.mode-toggle {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
/* css code for hiding the logo inside sidebar */
.logo-hidden {
  display: none;
}

.logo-visible {
  display: block;
}

/* css code for removing the border from tab inside event and alert dashboard */
.custom-tab .nav-link.active,
.custom-tab .nav-link,
.custom-tab .nav-link:focus,
.custom-tab .nav-link:hover {
  border: none !important;
}
.custom-tab .nav-link::before,
.custom-tab .nav-link.active::before,
.custom-tab .nav-link:focus::before,
.custom-tab .nav-link:hover::before {
  border: none !important;
}

/* css for making alert and event card image responsive when click on perticular event */
.showIcon {
  position: absolute;
  margin-left: 30px;
}
.card-img {
  height: 505px;
}
@media (max-width: 765px) {
  .card-img {
    height: 405px;
  }
  .content-below-image-alert-event-card {
    display: flex;
  }
}
@media (max-width: 765px) {
  .card-img {
    height: 205px;
  }
}

/* css for close button inside the card of event and alert table  */
.alertacard-close-button {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin-right: 0px;
  margin-top: 2px;
}
.alertacard-close-button img {
  width: 23px;
  height: 23px;
}
/* css for close icon on heatmap to close the heatmap */
.alertacard-close-button-heatmap {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 80;
  cursor: pointer;
  margin-right: 30px;
}
.alertacard-close-button-heatmap img {
  width: 23px;
  height: 23px;
  margin-top: 10px;
}

/* css for the filter applied on alert table and event table and retail dashboard  */
.filter-div {
  position: relative;
  height: 40px;
  width: 100%;
  color: var(--card-text-color);
  background-color: var(--input-select-bg-color);
  border-radius: 6px;
  cursor: pointer;
}

.filter-div .filter-div-text {
  margin-left: 10px;
  margin-top: 6px;
  line-height: 30px;
}
.filter-drop-icon {
  position: absolute;
  right: 0;
  background-color: var(--input-select-bg-color);
}

.phone-validation-error {
  color: red;
}
/* .user-uploaded-image img {
  height: 200px;
  width: 300px;
}
.user-uploaded-file {
  font-family: "Roboto";
  font-style: normal;
  padding: 10px;
  background-color: var(--user-sent-message-bg);
  border-radius: 32px;
}
.xllogo-image img {
  height: 60px;
  width: 60px;
}
.user-uploaded-file-info {
  margin-top: 15px;
}
.user-uploaded-file-info p {
  color: white;
  opacity: 1;
  line-height: 5px;
  font-weight: 400;
} */
@media (max-width: 900px) and (min-width: 800px) {
  #log-out-text {
    display: none;
  }
}

.profile-topbar-profile-image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.heatmap-no-data-to-show {
  margin-top: 10px;
}
.heatmap-no-data-to-show p {
  color: #9fa3bc;
}
.profile-options-container {
  background-color: var(--side-bar-bg-color);
  height: auto;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: start;
}

.profile-options-list {
  height: 50px;
  justify-content: center;
  border-radius: 2px;
  padding-left: 0;
  overflow: auto;
}
.profile-option-div {
  font-size: 20px;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 2px;
  margin-left: 0;
  transition: background-color 0.6s ease;
  color: var(--card-text-color);
}
.profile-option-div:hover {
  background-color: #3a7bd5;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.profile-option-div-active {
  background-color: #3a7bd5;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.image-account-div {
  display: flex;
}
.profile-top-card-container {
  display: flex;
  flex-direction: row;
  height: auto;
  background-color: #425cdf;
  background-position: inherit;
  padding-top: 3px;
  border-radius: 5px;
  justify-content: space-between;
  padding-bottom: 20px;
}

.profile-top-card-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  max-width: 185px;
  height: 180px;
  border-radius: 50%;
  background-color: #ffffff;
  top: 0px;
  left: 0px;
  margin-left: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.profile-top-card-logo-div-image {
  height: 150px;
  width: 70%;
  object-fit: contain;
}
.profile-top-card-logo-div-account-name {
  display: flex;
  font-size: 32px;
  color: #ffff;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.parent-profile-top-card-account-details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-top-card-account-details {
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-right: 40px;
  margin-left: 10px;
}
.profile-top-card-account-details .profile-detail-item {
  display: flex;
  float: right;
  color: white;
  font-size: 24px;
}

.profile-detail-item-icon .profile-detail-icon {
  font-size: 28px;
}
.profile-detail-item-text {
  font-size: 24px;
  margin-left: 10px;
}
.qr-container {
  height: auto;
  padding-bottom: 50px;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qr-container img {
  border: 1px solid gray;
  height: 300px;
  width: 300px;
}
@media (max-width: 1150px) {
  .profile-detail-item-text {
    margin-top: 6px;
    font-size: 16px;
  }
}
@media (max-width: 1000px) {
  .image-account-div {
    display: flex;
    flex-direction: column;
  }
  .profile-top-card-logo-div {
    margin-left: 15px;
  }
}
@media (max-width: 850px) {
  .profile-option-div {
    font-size: 18px;
    padding-top: 5px;
    margin-right: 5px;
    padding-top: 12px;
  }
}
@media (max-width: 550px) {
  .profile-top-card-logo-div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    max-width: 125px;
    height: 120px;
    border-radius: 50%;
    background-color: #ffffff;
    top: 0px;
    left: 0px;
    margin-top: 20px;
  }
  .image-account-div {
    align-items: center;
  }
  .profile-top-card-container {
    display: flex;
    flex-direction: column;
  }
  .parent-profile-top-card-account-details {
    display: flex;
    align-items: center;
    margin-left: 70px;
  }
  .profile-option-div {
    font-size: 14px;
    padding-top: 12px;
    margin-right: 5px;
  }
  .qr-container {
    height: auto;
    padding-bottom: 50px;
    margin-top: 10px;
    padding: 20px;
  }
  .qr-container img {
    border: 1px solid gray;
    height: 200px;
    width: 200px;
  }
  .profile-detail-item-icon .profile-detail-icon {
    font-size: 24px;
  }
  .profile-detail-item-text {
    font-size: 18px;
    margin-left: 10px;
    margin-top: 5px;
  }
}
@media (max-width: 400px) {
  .profile-option-div {
    font-size: 14px;
    padding-top: 5px;
    margin-right: 5px;
  }
}
@media (max-width: 320px) {
  .image-account-div {
    align-items: center;
  }
  .profile-detail-item-icon .profile-detail-icon {
    font-size: 24px;
  }
  .profile-detail-item-text {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 8px;
  }
  .parent-profile-top-card-account-details {
    display: flex;
    align-items: center;
    margin-left: 70px;
  }
  .profile-top-card-logo-div-account-name {
    display: flex;
    font-size: 20px;
  }
}
.rhms-table-container::-webkit-scrollbar {
  display: none;
}

.select-input-field-card {
  background-color: var(--input-select-bg-color);
  outline: 0px !important;
  height: 30px;
  width: 100%;
  color: var(--card-text-color);
  border-radius: 8px;
  margin-top: 0;
  padding-top: 0;
  padding-left: 2px;
  padding: 0px;
  text-indent: 0px;
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 4px;

  /* padding-left: 10px; */
}
.input-event-message {
  background-color: var(--input-select-bg-color);
  border: none !important;
  outline: 0px !important;
  height: 30px;
  width: 100%;
  color: var(--card-text-color);
  border-radius: 8px;
  margin-top: 0;
  padding-top: 0;
  padding: 0px;
  text-indent: 0px;
  display: inline-block;
  padding: 4px;
}
.table-card-save-btn {
  width: 100%;
  height: 30px;
  background-color: #1b2559;
  border: none;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 80px;
}
.remote-monitoring-detail-modal::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.remote-monitoring-detail-modal::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the scrollbar track */
}

.remote-monitoring-detail-modal::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 20px;
}

.remote-monitoring-detail-modal::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}
.form-group-edit-card {
  margin: 0px;
  padding: 0px;
  line-height: 100%;
  margin-top: 5px;
}
.form-content-edit-data {
  /* display: flex; */
  flex-direction: row;
}
.editTransferModal {
  margin-top: 2%;
}
.icons-above-table-card {
  position: absolute;
  right: 5px;
}
.table-card-icon {
  margin-bottom: 4px;
  margin-top: 0;
  cursor: pointer;
  color: white;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.table-card-icon:hover {
  transform: scale(1.1);
}
.custom-tooltip {
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #333;
  color: #fff;
  font-size: 14px;
  padding: 10%;
  border-radius: 4px;
  width: 200px;
}
.heatmap-chart-container {
  background-color: var(--side-bar-bg-color);
}
.apexcharts-legend-text {
  margin-top: 4px;
}
.highlighted-date {
  background-color: yellow;
}
.grid-icon {
  border: solid black 2px;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: bold;
  margin: 4px;
  border-radius: 10%;
  cursor: pointer;
}
.grid-icon-active {
  border: solid black 2px;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: bold;
  margin: 4px;
  border-radius: 10%;
  cursor: pointer;
  background-color: black;
  color: white;
}
.grid-icon:hover {
  border: solid black 2px;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: bold;
  margin: 4px;
  border-radius: 10%;
  cursor: pointer;
  background-color: black;
  color: white;
}
.alert-table-tab {
  position: relative;
}
.page-size-grid {
  display: flex;
  position: absolute;
  right: 0;
}
@media (max-width: 600px) {
  .page-size-grid {
    display: none;
  }
}
.go-to-page-div {
  /* padding: 0 12px; */
  height: 32px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  /* box-sizing: border-box; */
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 10px;
  line-height: 1.43;
  font-size: 13px;
  width: 100px;
  background-color: #fff;
}
.input-inside-pagination {
  margin: 5px;
  width: 75%;
  border: none;
  background-color: #f4f7fe;
  border-radius: 5px;
  padding-left: 6px;
}
.input-inside-pagination:focus {
  padding-left: 6px;
  border: none;
  outline: none;
}

.button-input-inside-pagination {
  background-color: transparent;
  border: none;
  padding-left: 5px;
  font-size: 17px;
  display: flex;
  justify-content: center;
}
.page-size-grid {
  margin-top: 10px;
  margin-right: 20px;
}
.loading-icons-rhms {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .go-to-page-div {
    margin: auto;
    margin-top: 2vh;
  }
}
.heatmap-image-div {
  background-color: var(--heatmap-card-bgcolor);
  border-radius: 15px;
  margin: 5px;
  cursor: pointer;
}
.heatmap-image-div img {
  width: 100%;
  border-radius: 15px;
}
.p-tag-heatmap {
  color: var(--card-text-color);
}
@media screen and (min-width: 980px) and (max-width: 1040px) {
  .p-tag-heatmap {
    font-size: small;
  }
}

@media screen and (min-width: 800px) and (max-width: 871px) {
  .p-tag-heatmap {
    font-size: small;
  }
}
