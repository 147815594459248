.file-upload-container {
  border: 1px dashed gray;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  border-radius: 10px;
  width: 70%;
  padding: 40px;
  cursor: pointer;
  border-spacing: 2rem;
}



.file-upload-container h6 {
  color: black !important;
  font-weight: 400;
  font-size: 18px !important;
}

.file-upload-container p {
  color: grey !important;
  font-weight: 200;
  font-size: 14px !important;
}

.image-preview-container {
  border: 1px dashed gray;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  border-radius: 10px;
  width: 70%;
  padding: 40px;

  border-spacing: 2rem;
}

.box-inside-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7b8ad5;
}

.icons-text {
  display: flex;
  flex-direction: column;
  color: white;
}

.Client-onboard-Nav {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.Client-onboard-Nav img {
  width: 211px;
  height: 46px;
  margin-left: 30px;
  margin-top: 20px;
}

#home_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Onboard-text {
  color: rgba(66, 92, 223, 1);
  text-align: left;
  font-weight: 700;
  margin-left: 60px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.onbaording-form-card {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: solid black 2px; */
  margin: auto;
}

.googleSignInButton {
  background-color: #333333;
  color: white;
  padding: 10px 30px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.googleSignInButton:hover {
  transform: scale(1.1);
}

.submit-button-onboarding {
  display: flex;
  justify-content: flex-end;
}

.button-group-onboarding {
  display: flex;
  /* justify-content: space-between; */
}

.action-button-stepper {
  display: flex;

  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
}

.facility-onboarding {
  font-family: Arial, sans-serif;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 20px auto;
}

.facility-onboarding p {
  margin: 10px 0;
}

.checkbox-group label {
  display: block;
  margin: 10px 0;
}

.onboard-field {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.typography-start {
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #agrex_logo {
    margin-top: 0px;
    margin-left: 0px;
  }

  #onboard_text {
    margin-top: 0px;
    margin-left: 25px;
  }
}