.email-notification-div {
  font-size: larger;
  color: var(--card-text-color);
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.recieve-email-text {
  margin-right: 10px;
}
.email-notification-div-parent {
  height: 600px;
  overflow-y: scroll;
  padding: 15px;
}
.email-notification-div-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--card-text-color);
  color: var(--card-text-color);
}
.add-button-user-setting {
  border: none;
  background: none;
  cursor: pointer;
}
@media (max-width: 400px) {
  .email-notification-div-container {
    display: flex;
    flex-direction: column;
  }
  .email-notification-save-button {
    margin-left: auto;
    margin-bottom: 5px;
  }
}
/* Style the scrollbar - webkit browsers */
.email-notification-div-parent::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  border-radius: 50%;
}

.email-notification-div-parent::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.email-notification-div-parent::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
}

.email-notification-div-parent::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* Style the scrollbar - Firefox */
.email-notification-div-parent {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}
