.heatmap-tab {
  width: 150px;
  height: 42px;
  margin-left: 10px;
  border-radius: 5px;
  color: var(--card-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: left;
  cursor: pointer;
}
.heatmap-tab-active {
  width: 150px;
  height: 42px;
  background-color: #697dff;
  margin-left: 10px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: background-color 0.5s ease;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: left;
  cursor: pointer;
}
.paraColorOfNvrRed {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #fb1010;
}
.nvr_online_color {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #33c200;
}
.showDetailsNvrButton {
  background-color: #d8d8d8;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: 0px;
}
.error-message {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 10%;
  margin-right: 10%;
}
.rhms-table-text-modal-details-label {
  font-size: 18px !important;
  font-weight: 1500 !important;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.rhms-table-text-modal-details {
  padding: 5px;
  display: flex;
  justify-content: center;
}
/* ModalStyles.css */

.header-background {
  background-color: #166df7;
  color: white;
}

.modal-title-color {
  font-size: 18px;
  font-weight: bold;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.custom-table th {
  background-color: #e9f0fc;
  color: #1273eb;
}

.available-days-header {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  /* display: flex; */
  justify-content: center;
}

.status-not-available {
  color: red;
}
